/* eslint-disable @typescript-eslint/no-explicit-any */
import ReactLinkify from 'react-linkify';
import { FormBuilderPlaceholder } from '../components/form-builder/FormBuilderTypes';
import { Heading, HeadingSize } from '../components/shared/text/Heading';
import { ActionPlaceholderData } from '../models/Form';
import ReactPlayer from 'react-player';
import { SecureLink } from 'react-secure-link';
import { interpolateActionData } from './interpolation/ActionDataInterpolator';

export const HEADINGS = ['h1', 'h2', 'h3', 'h4', 'h5'];
export const LISTS = ['ol', 'ul'];
export const BLOCKS = [...HEADINGS, ...LISTS];
export const MARKS = ['strong', 'em'];

export const interpolateChildren = (
  children: any,
  placeholders: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[],
  onClick?: () => void,
) => {
  if (!children) {
    return children;
  }

  if (!Array.isArray(children)) {
    if (typeof children === 'string') {
      return (
        <ReactLinkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <SecureLink href={decoratedHref} key={key} className="text-link-1 font-bold underline" onClick={onClick}>
              {decoratedText}
            </SecureLink>
          )}
        >
          {interpolateActionData(children, placeholders)}
        </ReactLinkify>
      );
    }

    return children;
  }

  return children.map((child: any, i) => {
    if (typeof child === 'string') {
      return (
        <ReactLinkify
          key={i}
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <SecureLink href={decoratedHref} key={key} className="text-link-1 font-bold underline" onClick={onClick}>
              {decoratedText}
            </SecureLink>
          )}
        >
          {interpolateActionData(child, placeholders)}
        </ReactLinkify>
      );
    }

    return child;
  });
};

export type MarkdownRenderersType = keyof ReturnType<typeof renderer>;

const renderer = (
  placeholders: Record<string, ActionPlaceholderData> | FormBuilderPlaceholder[],
  tagProps?: Record<string, Record<string, any>>,
) => ({
  a(props: any) {
    const re = /(youtube\.com|vimeo\.com|facebook\.com|soundcloud\.com|wistia\.com|mixcloud\.com|dailymotion\.com|twitch\.tv)/gm;
    if (re.test(props.href)) {
      return <ReactPlayer url={props.href} controls />;
    }
    return (
      <a className="text-link-1 cursor-pointer font-medium hover:underline" href={props.href} {...(tagProps?.['a'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </a>
    );
  },
  h1(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h1'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  h2(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h2'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  h3(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h3'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  h4(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h4'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  h5(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h5'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  h6(props: any) {
    return (
      <Heading size={props?.node?.tagName ?? (`h${props.level}` as HeadingSize)} {...(tagProps?.['h6'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </Heading>
    );
  },
  p(props: any) {
    return (
      // Note: p tags cannot contain block elements. Since the editor produces such cases, we use a div here instead
      <div {...(props.attributes || {})} className={`${props.ignorePadding ? 'my-0' : 'my-1'}`} {...(tagProps?.['p'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </div>
    );
  },
  ol(props: any) {
    return (
      <ol className="ml-8 list-outside list-decimal" {...(props.attributes || {})} {...(tagProps?.['ol'] || {})}>
        {props.children}
      </ol>
    );
  },
  ul(props: any) {
    return (
      <ul className="ml-8 list-outside list-disc" {...(props.attributes || {})} {...(tagProps?.['ul'] || {})}>
        {props.children}
      </ul>
    );
  },
  li(props: any) {
    return (
      <li {...(props.attributes || {})} {...(tagProps?.['li'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </li>
    );
  },
  hr(props: any) {
    return (
      <div {...(props.attributes || {})} className="border-gray-4 my-2 border-b" {...(tagProps?.['hr'] || {})}>
        {props.children}
      </div>
    );
  },
  em(props: any) {
    return (
      <span {...(props.attributes || {})} className="italic" {...(tagProps?.['em'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </span>
    );
  },
  strong(props: any) {
    return (
      <span {...(props.attributes || {})} className="font-bold" {...(tagProps?.['strong'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </span>
    );
  },
  underline(props: any) {
    return (
      <span {...(props.attributes || {})} className="underline" {...(tagProps?.['underline'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </span>
    );
  },
  code(props: any) {
    return (
      <code {...(props.attributes || {})} className="code" {...(tagProps?.['code'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </code>
    );
  },
  br(props: any) {
    return <br {...(props.attributes || {})} {...(tagProps?.['br'] || {})} />;
  },
  blockquote(props: any) {
    return (
      <div {...(props.attributes || {})} className="border-gray-3 ml-2 border-l-2 pl-2" {...(tagProps?.['blockquote'] || {})}>
        {interpolateChildren(props.children, placeholders, tagProps?.['a']?.onClick)}
      </div>
    );
  },
});

export default renderer;
